import React, { useState, useEffect } from "react"
import { FaFileImage, FaFilePdf } from "react-icons/fa"
import { getFileNameUsingCurrentTime } from "../../common/FileNameCurrentTime/FileNameCurrentTime"
import { getArtworkOfExistingLabel } from "../../services/aiApis"

const isS3PresignedUrl = (url) => {
  // (.includes("https://"), but startsWith is more precise)
  return url.toLowerCase().startsWith("https://")
}

const PreviousArtworks = ({ name, version, status, files, businessId }) => {
  const [isPdf, setIsPdf] = useState(false)

  useEffect(() => {
    if (Array.isArray(files)) {
      setIsPdf(false)
    } else {
      setIsPdf(true)
    }
  }, [files])

  const downloadSingleFile = async (url, index) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, index * 1000))

      if (isS3PresignedUrl(url)) {
        const anchor = document.createElement("a")

        anchor.href = url

        anchor.download = `${name}_${index + 1}.jpeg`

        document.body.appendChild(anchor)

        anchor.click()

        document.body.removeChild(anchor)
      } else {
        const response = await getArtworkOfExistingLabel(businessId, url)

        if (response.data) {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          })

          const blobUrl = URL.createObjectURL(blob)

          const anchor = document.createElement("a")

          anchor.href = blobUrl

          anchor.download = `${name}_${index + 1}.jpeg`

          document.body.appendChild(anchor)

          anchor.click()

          document.body.removeChild(anchor)

          URL.revokeObjectURL(blobUrl)
        }
      }
    } catch (error) {}
  }

  // Downloads an array of files, with a delay for each.
  const downloadFilesWithDelay = (fileUrls) => {
    fileUrls.forEach((url, index) => {
      downloadSingleFile(url, index)
    })
  }

  // Event handler when user clicks the artwork.
  const onArtworkClick = () => {
    if (Array.isArray(files)) {
      downloadFilesWithDelay(files)
    } else {
      downloadFilesWithDelay([files])
    }
  }

  return (
    <div className="flex flex-row" onClick={onArtworkClick}>
      <div className="flex gap-1 bg-[#F1F1F1] p-2 m-1 rounded-md border w-2/4">
        <div className="flex">
          {isPdf ? (
            <FaFilePdf className="text-red-500 mr-2 mt-1" />
          ) : (
            <FaFileImage className="text-blue-500 mr-2 mt-1" />
          )}
          <span className="font-medium">Version {version}</span>
        </div>
      </div>
      <div className="flex items-center">
        <span
          className={`flex border text-xs rounded-lg m-1 p-1 items-center ${
            status === "Pending"
              ? "text-yellow-500"
              : status === "Approved"
              ? "text-green-500"
              : status === "Rejected"
              ? "text-red-500"
              : "text-yellow-500"
          }`}
        >
          {status}
        </span>
      </div>
    </div>
  )
}

export default PreviousArtworks
