import axios from "axios"
import config from "../config"
import { getCommonHeaders } from "./ApiServicesv2"

export const createAILabel = async (data) => {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.post(
      `${config.APIENDPOINT}` + "/api/v2/ai_label/v2",
      data,
      {
        headers,
      },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const getAILabelByIdWithVersion = async (id, version) => {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}` + `/api/v2/ai_label/v2/${id}/${version}`,
      {
        headers,
      },
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const updateAiLabelById = async (id, data) => {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.put(
      `${config.APIENDPOINT}/api/v2/ai_label/v2/${id}`,
      data,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const getArtworkOfExistingLabel = async (business_id, name) => {
  try {
    const headers = await getCommonHeaders()

    const data = {
      name: name,
    }

    const response = await axios.post(
      `${config.APIENDPOINT}` + `/api/v2/ai_label/download_file/${business_id}`,
      data,
      {
        headers,
        responseType: "blob",
      },
    )
    return response
  } catch (error) {
    return error.response.data
  }
}
