import React, { useEffect, useState } from "react"
import PlusCircle from "../common/Icons/PlusCircle"
import { DustbinIcon } from "../assets/icons/Icons"
import QkFieldInput from "./QkFieldInput"
import { classNames, uuidv4 } from "../Utils/helpers"

export default function QkMultiInput({
  data,
  parentData,
  values,
  translated_values,
  handleChangeParent,
  handleTranslateChangeParent,
  repeatable,
  draggable,
  hide_add_more,
}) {
  const [groupIds, setGroupIds] = useState([
    parentData.uuid ? `${uuidv4()}` : "",
  ])

  useEffect(() => {
    let initialLength

    initialLength = Math.max(parentData.initialLength ?? 1, values?.length ?? 1)

    let tempArr = []
    if (initialLength > 0 && parentData.uuid) {
      Array(initialLength)
        .fill(0)
        .forEach(() => tempArr.push(uuidv4()))
    } else if (initialLength > 0) {
      Array(initialLength)
        .fill(0)
        .forEach(() => tempArr.push(""))
    } else {
      tempArr.push("")
    }
    setGroupIds([...tempArr])
  }, [parentData])

  const handleChange = (value, key, index) => {
    if (repeatable) {
      let newValues = values ?? Array(groupIds.length).fill({})
      newValues[index] = {
        ...newValues[index],
        [key]: value,
      }
      if (parentData.uuid) {
        newValues[index].uuid = groupIds[index]
      }
      parentData.uuid
        ? handleChangeParent({
            changeUuid: newValues[index].uuid,
            inputArray: newValues,
          })
        : handleChangeParent(newValues)
    } else {
      if (values) {
        handleChangeParent([
          {
            ...values[index],
            [key]: value,
          },
        ])
      } else {
        handleChangeParent([
          {
            [key]: value,
          },
        ])
      }
    }
  }

  const handleTranslateChange = (value, translate_key, index) => {
    if (repeatable) {
      let newValues = translated_values ?? Array(groupIds.length).fill({})
      newValues[index] = {
        ...newValues[index],
        [translate_key]: value,
      }
      if (parentData.uuid) {
        newValues[index].uuid = groupIds[index]
      }
      parentData.uuid
        ? handleTranslateChangeParent({
            changeUuid: newValues[index].uuid,
            inputArray: newValues,
          })
        : handleTranslateChangeParent(newValues)
    } else {
      if (translated_values) {
        handleTranslateChangeParent([
          {
            ...translated_values[index],
            [translate_key]: value,
          },
        ])
      } else {
        handleTranslateChangeParent([
          {
            [translate_key]: value,
          },
        ])
      }
    }
  }

  const deleteComponent = (index) => {
    let tempArray = groupIds
    tempArray.splice(index, 1)
    setGroupIds(tempArray)
    let newValues = values
    newValues.splice(index, 1)
    handleChange(newValues)
  }
  const editable = parentData.hasOwnProperty("editable")
    ? parentData.editable
    : true
  const clearValue = () => {
    let temp = JSON.parse(JSON.stringify(values))
    temp.forEach((value) => {
      Object.keys(value).forEach((key) => {
        value[key] = ""
      })
    })
    handleChangeParent(temp)
    setGroupIds([parentData.uuid ? `${uuidv4()}` : ""])
  }

  return (
    <>
      {<div className="italic ml-5">{parentData?.infoText}</div>}

      {groupIds.map((id, index) => {
        let value = {}
        if (values && values.length > index) {
          value = values[index]
        }

        let translated_value = {}
        if (translated_values && translated_values.length > index) {
          translated_value = translated_values[index]
        }

        return (
          <div
            className={classNames(
              "relative px-4 py-1 rounded-lg my-1",
              !editable && "disabled",
              data.disabled && "cursor-not-allowed opacity-50",
            )}
            key={index}
          >
            {repeatable && (
              <button
                className="absolute top-2 right-1 pt-3 z-10"
                onClick={() => {
                  if (groupIds.length === 1) {
                    clearValue()
                  } else {
                    deleteComponent(index)
                  }
                }}
              >
                {groupIds.length === 1
                  ? Object.keys(value).length > 0 && (
                      <DustbinIcon className="w-4 h-4" />
                    )
                  : Object.keys(value).length > 0 && (
                      <DustbinIcon className="w-4 h-4" />
                    )}
              </button>
            )}

            {/*<div className="grid grid-cols-12 gap-8 max-w-[95%]">
               {data?.fields &&
              data.fields.some(
                (field) => field.key === "name" || field.key === "quantity",
              )
                ? Object.keys(value).length > 0 &&
                  data?.fields?.map((field) => (
                    <QkFieldInput
                      key={field.key}
                      data={field}
                      values={value}
                      uuid={id}
                      index={index}
                      handleChange={(d) => handleChange(d, field.key, index)}
                    />
                  ))
                : data?.fields?.map((field, index) => (
                    <QkFieldInput
                      key={field.key}
                      data={field}
                      values={value}
                      uuid={id}
                      index={index}
                      handleChange={(d) => handleChange(d, field.key, index)}
                    />
                  ))} 
                  </div>
                  */}
            <div
              className={`grid grid-cols-12 gap-4 max-w-[98%] ${
                data.disabled ? "cursor-not-allowed opacity-50" : ""
              }`}
            >
              {data?.fields?.map((field) => {
                return (
                  <QkFieldInput
                    key={field.key}
                    data={field}
                    values={value}
                    translated_values={translated_value}
                    uuid={id}
                    index={index}
                    handleChange={(d) => handleChange(d, field.key, index)}
                    handleTranslateChange={(d) =>
                      handleTranslateChange(d, field.translate_key, index)
                    }
                  />
                )
              })}
            </div>

            {index !== groupIds.length - 1 && <hr className="my-2" />}
          </div>
        )
      })}

      {repeatable && !hide_add_more && (
        <button
          className="flex p-1 items-center font-bold"
          onClick={() =>
            setGroupIds([...groupIds, parentData.uuid ? `${uuidv4()}` : ""])
          }
        >
          <PlusCircle className="h-4 w-4 text-primary" />
          <h1 className="text-primary ml-1 text-sm">Add More</h1>
        </button>
      )}
    </>
  )
}
